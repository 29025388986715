/* eslint-disable */
import $ from 'jquery';
import 'foundation-sites/js/foundation/foundation.alert';
import carousel from '../common/carousel';
import utils from '@bigcommerce/stencil-utils';
import collapsibleFactory from '../common/collapsible';
//import WOW from 'wow.js'
//import 'jquery-countdown';

export default function (context) {
    $(document).foundation();
    
    // ========================================================================
    // Show Back To Top
    // ========================================================================
    function backScrollTop() {
        const scrollToTop = $('.scrollToTop');
       
        scrollToTop.addClass("hidden-top");
        $(window).scroll(function () {
            if ($(this).scrollTop() === 0) {
                scrollToTop.addClass("hidden-top")
            } else {
                scrollToTop.removeClass("hidden-top")
            }
        });

        scrollToTop.click(function () {
            $('body,html').animate({scrollTop:0}, 500);
            return false;
        });
     
       
    }
    backScrollTop();
    
    
	function colorSwatches(){
		const colorSwatches = $(".color_swatches");
		const productshipping = $(".color_shipping");
		colorSwatches.each(function(i) {
			const productID = $(this).parents('.right-block').data('card-body');
			utils.api.product.getById(productID, { template: 'wbcomponents/products/colorswatches' }, (err, response) => {
				if(response){
					var swatch = $(response).filter('div[data-product-attribute="swatch"]').html();
					
					$('.right-block[data-card-body="'+productID+'"] .color_swatches').html(swatch);
					$('.right-block[data-card-body="'+productID+'"] .color_swatches').find("input").remove();
					$('.right-block[data-card-body="'+productID+'"] .color_swatches').find(".form-label.form-label--alternate").remove();
					$('.right-block[data-card-body="'+productID+'"] .color_swatches').find(".form-option-variant").unwrap();
					
				}else{
					$(this).remove();
				}
				
			});
			
		});
		
		productshipping.each(function(i) {
			const productID = $(this).parents('.right-block').data('card-body');
			utils.api.product.getById(productID, { template: 'wbcomponents/products/colorswatches' }, (err, response) => {
				if(response){
					var shipping = $(response).filter('.productView-info-dl').html();
					$('.right-block[data-card-body="'+productID+'"] .color_shipping').html(shipping);
				}
				
			});
		});
	}
    
    // ------------------------------------------------------------------------
    // Product CountDown
    // ------------------------------------------------------------------------
    function productCountDown() {
        const countDownProduct = $(".defaultCountdown");
        countDownProduct.each(function(i) {
            $('.defaultCountdown-'+$(this).data("countdown-id")).countdown($(this).data("timer"), function(event) {
                var $this = $(this).html(event.strftime(
                    '<div class="time-item time-day"><div class="num-time">%D</div><div class="name-time">Day%!d </div></div>'
                   + '<div class="time-item time-hour"><div class="num-time">%H</div><div class="name-time">Hour%!H</div></div>'
                   + '<div class="time-item time-min"><div class="num-time">%M</div><div class="name-time">Min%!M </div></div>'
                   + '<div class="time-item time-sec"><div class="num-time">%S</div><div class="name-time">Sec%!S</div></div>'));
                $this.on('finish.countdown', function(event){
                    $(this).hide();
                });
            });
            
            // $('.defaultCountdown-'+$(this).data("countdown-id")).countdown('stop');
        });
         
    }
    productCountDown();

   
    // ========================================================================
    // WBTheme - CardGallery
    // ========================================================================
	
    function cardGallery() {
        $('.product-card__gallery .item-img').on("mouseover touchstart", function (e) {
            $(this).addClass('thumb-active').siblings().removeClass('thumb-active');
            var thumb_src = $(this).attr("data-src");
            $(this).closest('.product-item-container').find('img.img-responsive').attr("src",thumb_src);
        }); 
    }
    cardGallery();
   
    // ========================================================================
    // WBTheme - getPage Ajax Product
    // ========================================================================
    function getPage($placeholder, tmpl, urlKey = 'itemsCategoryurl') {
        let template = tmpl;
        if ($placeholder.data('urltemplate')) { template = $placeholder.data('urltemplate'); }
        let url = $placeholder.data(urlKey);
        url = url.replace(/https?:\/\/[^\/]+/, ''); 

        utils.api.getPage(url, { template }, (err, resp) => {
            $placeholder.html(resp);
           
            // init products carousel
            $('[data-slick]', $placeholder).slick();
            cardGallery();
            productNewLabel();
			productCountDown();
        });
    }
    
    // ========================================================================
    // WBTheme -  Ajax initAjaxCategoryTabs
    // ========================================================================
    function initAjaxCategoryTabs() {
        const template = 'wbcomponents/addons/wb_deal/default/default_deals_item';
        const urlKey = 'productsByCategoryTabs';
        $('[data-products-by-category-tabs]').each((i, placeholder) => {
            getPage($(placeholder), template, urlKey);
          
        });
        
    }
    initAjaxCategoryTabs();

    function enableSelectBoxes() {
		$('.wb-listing-tabs').each(function() {
			const tag_id =  $(this).attr('id');
			
			(function (element) {
				const $element = $(element);
				const $tab = $('.tab', $element);
				const $tab_wrap = $('.ltabs-tabs-wrap',$element);
                const $tab_label_select = $('.ltabs-tab-selected',$element);
                
				if($tab.length > 0){
                    $tab_label_select.html($tab.filter(':first').children('.tab-title').html());
                    if ( window.innerWidth <= 991) {
                        $tab_wrap.addClass('ltabs-selectbox');
                    } else {
                        $tab_wrap.removeClass('ltabs-selectbox');
                    }
                    
                    $('span.ltabs-tab-selected, span.ltabs-tab-arrow', $element).click(function () {
                        if ($('.ltabs-tabs', $element).hasClass('ltabs-open')) {
                            $('.ltabs-tabs', $element).removeClass('ltabs-open');
                        } else {
                            $('.ltabs-tabs', $element).addClass('ltabs-open');
                        }
                    });
                    $tab.click(function () {
                        if ($(this).parents('.ltabs-tabs').hasClass('ltabs-open')) {
                            $(this).parents('.ltabs-tabs').removeClass('ltabs-open');
                        }
                        $tab_label_select.html($(this).children('.tab-title').html());
                    });
                    
                    $(window).resize(function () {
                        if ($(window).innerWidth() <= 991) {
                            $tab_wrap.addClass('ltabs-selectbox');
                        } else {
                            $tab_wrap.removeClass('ltabs-selectbox');
                        }
                    });
                }
				
			})('#'+tag_id);
		});
	}
	enableSelectBoxes();
    // ========================================================================
    // Ajax load  in  listing tabs
    // ========================================================================
    function initAjaxListingTabs() {
        const template = 'wbcomponents/addons/sw_listing_tabs/default_items';
        const urlKey = 'itemsListingtabs';

        // Ajax request loading products in the active tab
        $('.is-active[data-items-listingtabs]').each((i, placeholder) => {
            getPage($(placeholder), template, urlKey);
        });

        $('[data-tab-listingtab]').on('toggled', (event, tab) => {
            getPage($($('a', tab).attr('href')), template, urlKey);
        });
    }
    initAjaxListingTabs();

    // ========================================================================
    // WBTheme -  ProductNewLabel
    // ========================================================================
    function productNewLabel(){
        const arrProductNew = [];
        const objProductNew = context.productNew;
        $(objProductNew).each(function(index, el) {
            arrProductNew.push($(this).attr('url'));
        });
         
       // new label show on product items
        $('.product-item-container').each(function(index, el) {
            var thisProd = $(this).find('.product-item-photo').attr('href');
            for (var i = 0; i < arrProductNew.length; i++) {
                if ((arrProductNew[i].indexOf(thisProd) > 0) || (arrProductNew[i] === thisProd )) {
                    if($(this).find('.left-block').find('.sale-flag-side--new').length <= 0){
                        $(this).find('.product-item-photo').before('<div class="sale-flag-side sale-flag-side--new">New</div>');
                    }
                     
                }
            }
        });
    }
    
    // ------------------------------------------------------------------------
    // Collapsibles Categories on sidebar
    // ------------------------------------------------------------------------
    const $navListToggle = $('.navList .navList-toggle');
    const $curMenuItem = $('.navList .is-current');
    const collapsibles = [];
    collapsibleFactory();
    if ($curMenuItem.hasClass('has-subMenu')) {
        collapsibles.push($curMenuItem.parent('.navList-item').children('[data-collapsible]'));
    }

    $navListToggle.parents('.navList-subMenu-item, .navList-item').children('[data-collapsible]').each((i, el) => {
        collapsibles.push(el);
    });

    $.each(collapsibleFactory(collapsibles), (i, o) => {
        o.close();
    });

    // Resonsive footer-info-heading
     $(".collapsed-block .expander").click(function (e) {
        var collapse_content_selector = $(this).parent().next();
        var expander = $(this).parent();
        
        if (!$(collapse_content_selector).hasClass("open")) {
            expander.addClass("open") ;
        }
        else expander.removeClass("open");
        
        if (!$(collapse_content_selector).hasClass("open")) $(collapse_content_selector).addClass("open").slideDown("normal");
        else $(collapse_content_selector).removeClass("open").slideUp("normal");
        e.preventDefault()
    })
    
    
    // Resonsive Sidebar aside
    if(!$(".sidebar-offcanvas").length){
        $(".open-sidebar").hide();
    }

    $(".open-sidebar").click(function(e){
        e.preventDefault();
        
        $(".sidebar-overlay").toggleClass("show");
        if($(".sidebar-offcanvas").length){
            $(".sidebar-offcanvas").toggleClass("active");
        }else{
            $(this).hide();
        }
        
    });
        
    $(".sidebar-overlay").click(function(e){
        e.preventDefault();
        $(".sidebar-overlay").toggleClass("show");
        $(".sidebar-offcanvas").toggleClass("active");
    });
    $('#close-sidebar').click(function() {
        $('.sidebar-overlay').removeClass('show');
        $('.sidebar-offcanvas').removeClass('active');
        
    }); 
    // ========================================================================
    // WBTheme -  Show Megamenu
    // ========================================================================
    const itemver = $('[data-collapsible-limit]').data("collapsible-limit");
    const textmore = $('[data-collapsible-limit]').data("collapsible-textmore");
    const textclose = $('[data-collapsible-limit]').data("collapsible-textclose");
    
    function showMega() {
        var verticalMega =$(".verticalCategories ul.navPages-list > li");
        if(itemver < $(verticalMega).length) $('.verticalCategories ul.navPages-list').append('<li class="navPages-item loadmore"><div class="navPages-action"><i class="fa fa-plus-circle"></i><span class="more-view"> '+textmore+'</span> </div></li>');
        var show_itemver = itemver-1 ;
        $(verticalMega).each(function(i){
            if(i>show_itemver){
                $(this).css('display', 'none');
            }
        });
        
        $(".verticalCategories .loadmore").click(function(){
            if($(this).hasClass('open')){
                $(verticalMega).each(function(i){
                    if(i>show_itemver){
                        $(this).slideUp(200);
                        $(this).css('display', 'none');
                    }
                });
                $(this).removeClass('open');
                $('.loadmore').html('<div class="navPages-action"><i class="fa fa-plus-circle"></i> <span class="more-view">' +textmore+ '</span></div>');
            }else{
                $(verticalMega).each(function(i){
                    if(i>show_itemver){
                        $(this).slideDown(200);
                    }
                });
                $(this).addClass('open');
                $('.loadmore').html('<div class="navPages-action"><i class="fa fa-minus-circle"></i> <span class="more-view">'+textclose+'</span></div>');
            }
        });
        
        var wd_width = $(window).width();
        if (wd_width <= 1200) {
            $('.megamenuToogle-wrapper').on('touchstart', function(e){
                e.preventDefault();
                e.stopPropagation();
                if (!$(this).next().hasClass("is-open")) {
                    $(this).next().addClass("is-open");
                    
                }else{
                    $(this).next().removeClass("is-open");
                }
                
            });
           
        }
    }
    
    showMega();
    
	
	
	// ========================================================================
    // Get wowjs
    // ========================================================================
	function getwowjs(){
		const wow = new WOW({
		  boxClass: 'wow',
		  animateClass: 'animated',
		  offset: 0,
		  live: true
		});
		wow.init();
	}
	getwowjs();
	
	// ========================================================================
    // Points of Interest
    // ========================================================================
	function pointsInterest(){
		//open interest point description
		$('.list-singlepoint-item').children('.singlepoint-replace').on('click', function(){
			var selectedPoint = $(this).parent('.list-singlepoint-item');
			if( selectedPoint.hasClass('is-open') ) {
				selectedPoint.removeClass('is-open').addClass('visited');
			} else {
				selectedPoint.addClass('is-open').siblings('.list-singlepoint-item.is-open').removeClass('is-open').addClass('visited');
			}
		});
		//close interest point description
		$('.singlepoint-close').on('click', function(event){
			event.preventDefault();
			$(this).parents('.list-singlepoint-item').eq(0).removeClass('is-open').addClass('visited');
		});
	}
	pointsInterest();
	
	
}
