/* eslint-disable */
import $ from 'jquery';
import './common/select-option-plugin';
import PageManager from './page-manager';
import quickSearch from './global/quick-search';
import currencySelector from './global/currency-selector';
import mobileMenuToggle from './global/mobile-menu-toggle';
import menu from './global/menu';
import foundation from './global/foundation';
import quickView from './global/quick-view';
import cartPreview from './global/cart-preview';
import compareProducts from './global/compare-products';
import privacyCookieNotification from './global/cookieNotification';
import maintenanceMode from './global/maintenanceMode';
import carousel from './common/carousel';
import 'lazysizes';
import loadingProgressBar from './global/loading-progress-bar';
import sweetAlert from './global/sweet-alert';
import svgInjector from './global/svg-injector';
//wbtheme add
import wbtheme from './wbtheme/theme';
import WBNewsletterPopup from './wbtheme/newsletterPopup';
import WBStickyHeader from './wbtheme/stickyHeader';
import WBaddToCart from './wbtheme/addToCart';
import WBInstagramFeed from './wbtheme/instagramFeed';

window.WBNewsletterPopup = WBNewsletterPopup;
window.WBStickyHeader = WBStickyHeader;
window.WBInstagramFeed = WBInstagramFeed;
window.WBaddToCart = WBaddToCart;

export default class Global extends PageManager {
    onReady() {
        // Only load visible elements until the onload event fires,
        // after which preload nearby elements.
        window.lazySizesConfig = window.lazySizesConfig || {};
        window.lazySizesConfig.loadMode = 1;

        cartPreview(this.context.secureBaseUrl);
        quickSearch();
        currencySelector();
        foundation($(document));
        quickView(this.context);
        compareProducts(this.context.urls);
        carousel();
        menu();
        mobileMenuToggle();
        privacyCookieNotification();
        maintenanceMode(this.context.maintenanceMode);
        loadingProgressBar();
        sweetAlert();
        svgInjector();
        wbtheme(this.context); 
        
    }
}
